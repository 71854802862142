/* Global Styling */
body {
  background-color: #e0f7fa;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow-x: hidden;
}

.container {
  max-width: 800px;
  width: 100%;
  min-height: 90vh;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Header Bar for Minimized Info */
.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-size: 0.9em;
}

.header-item {
  background-color: #f1f1f1;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Title styling */
h1 {
  font-size: 2.0em;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Comic Sans MS', cursive;
  text-shadow: 1px 1px #ccc;
}

/* Input styling */
input,
button {
  font-size: 1.1em;
  padding: 12px;
  margin: 10px 0;
  border-radius: 8px;
  border: none;
}

input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 2px solid #ddd;
  transition: border-color 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #007bff;
  background-color: #e0f7fa;
}

/* Button Styles */
button {
  cursor: pointer;
  font-size: 1rem;
  border-radius: 8px;
  padding: 10px 15px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

/* Different button colors */
.start-button {
  background-color: #4caf50;
  color: white;
}

.start-button:hover {
  background-color: #43a047;
}

.submit-button {
  background-color: #007bff;
  color: white;
}

.submit-button:hover {
  background-color: #0056b3;
}

.clue-button {
  background-color: #ff9800;
  color: white;
}

.clue-button:hover {
  background-color: #e68900;
}

.question-button {
  background-color: #f44336;
  color: white;
}

.question-button:hover {
  background-color: #d32f2f;
}

.clear-button {
  background-color: #ff4d4d;
  color: white;
}

.clear-button:hover {
  background-color: #ff1a1a;
}

/* Clue Styling */
.clue {
  color: #007bff;
  margin-bottom: 10px;
  font-size: 1.1rem;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.clue.active {
  font-weight: bold;
  transform: scale(1.05);
}

.clue.inactive {
  color: #999;
  opacity: 0.7;
}

.clue.hidden {
  display: none;
}

/* Feedback Styling */
.feedback {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #4caf50;
}

/* Button Group Styling */
.button-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0;
}

.button-group button {
  flex-grow: 1;
}

/* Leaderboard Styling */
.leaderboard {
  margin: 20px auto;
  max-width: 600px;
  display: none;
}

.leaderboard.visible {
  display: block;
}

.leaderboard table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.leaderboard th,
.leaderboard td {
  padding: 10px;
  border: 2px solid #ddd;
  text-align: center;
  font-size: 1.1em;
}

.leaderboard th {
  background-color: #007bff;
  color: white;
}

.leaderboard td {
  background-color: #f9f9f9;
}

/* Error Styling */
.error {
  color: red;
  font-weight: bold;
  margin: 10px 0;
}

input:focus,
button:focus {
  outline: none;
}

button:active {
  transform: scale(0.98);
}

/* Table for leaderboard (hover effect) */
.leaderboard td:hover {
  background-color: #f1f1f1;
  transition: background-color 0.3s ease;
}

.source-file {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 0.8em;
  color: #888;
}

/* Media Queries for Small Screens */
@media (max-width: 768px) {
  body {
    align-items: flex-start;
    padding: 10px;
  }

  .container {
    padding: 10px;
    min-height: calc(100vh - 20px);
    border-radius: 10px;
  }

  h1 {
    font-size: 1.5rem;
  }

  input, button {
    font-size: 1rem;
    padding: 8px;
  }

  .button-group {
    flex-direction: column;
  }

  .button-group button {
    width: 100%;
    margin: 5px 0;
  }

  .header-bar {
    font-size: 0.8em;
    flex-wrap: wrap;
  }

  .header-item {
    margin: 2px;
    flex-grow: 1;
  }

  .leaderboard th,
  .leaderboard td {
    padding: 5px;
    font-size: 0.9em;
  }

  .source-file {
    position: static;
    display: block;
    text-align: right;
    margin-top: 10px;
  }
}